body {
  margin: 0;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inlineErrorMessage {
  font-family: Encode Sans;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  color: #f40c28;
  margin: 4px;
  font-size: 10px;
}

.inlineErrorMessage.pull-right {
  right: 0;
}

div.textarea ~ .inlineErrorMessage {
  margin-top: -15px;
}

.custom-notify {
  border-radius: 10px 20px 15px 20px;
  font-family: Encode Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px;
}

.custom-notify > div > svg {
  position: absolute;
  left: 10px;
}

.Toastify__toast--success {
  background: #a7bf2e !important;
}

.Toastify__toast--error {
  background: #f40c28 !important;
}
